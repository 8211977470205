import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Spinner from '../Spinner/Spinner';
import axios from 'axios';
import './CourseSelect.scss';

// Haversine formula to calculate distance between two lat/lng points
const toRad = (value) => (value * Math.PI) / 180;
const haversine = (lat1, lon1, lat2, lon2) => {
  const R = 6371; // Radius of the Earth in km
  const dLat = toRad(lat2 - lat1);
  const dLon = toRad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c; // Distance in km
};

const connection = process.env.REACT_APP_API_URL;

const CourseSelect = () => {
  const [courses, setCourses] = useState([]);
  const [organizedCourses, setOrganizedCourses] = useState({});
  const [filteredCourses, setFilteredCourses] = useState({});
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedCourse, setSelectedCourse] = useState('');
  const [selectedBlock, setSelectedBlock] = useState('');
  const [tournaments, setTournaments] = useState([]);
  const [selectedTournament, setSelectedTournament] = useState('');
  const [loading, setLoading] = useState(true);
  const [filtering, setFiltering] = useState(false);
  const [userLocation, setUserLocation] = useState(null);
  const [showModal, setShowModal] = useState(true);
  const [error, setError] = useState(null);
  const radius = 5; // Default radius is 5 km

  // Fetch courses
  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const token = localStorage.getItem('jwtToken');
        if (!token) {
          setError('Authentication failed. Please log in.');
          setLoading(false);
          return;
        }
        const response = await axios.get(`${connection}/courses`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        const sortedCourses = response.data.sort((a, b) =>
          a.city.localeCompare(b.city)
        );
        const cityGroups = sortedCourses.reduce((acc, course) => {
          const { city } = course;
          if (!acc[city]) acc[city] = [];
          acc[city].push(course);
          return acc;
        }, {});

        setCourses(sortedCourses);
        setOrganizedCourses(cityGroups);
        setFilteredCourses(cityGroups);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching courses:', error);
        setError('Failed to load courses. Please try again.');
        setLoading(false);
      }
    };

    fetchCourses();
  }, []);

  // Filter courses based on user location
  useEffect(() => {
    if (userLocation) {
      setFiltering(true);
      setTimeout(() => {
        const lat = userLocation.latitude;
        const lon = userLocation.longitude;

        const latRange = 0.5;
        const lonRange = 0.5;

        const preFiltered = courses.filter((course) => {
          return (
            Math.abs(course.latitude - lat) <= latRange &&
            Math.abs(course.longitude - lon) <= lonRange
          );
        });

        const filtered = preFiltered.filter((course) => {
          const distance = haversine(
            lat,
            lon,
            course.latitude,
            course.longitude
          );
          course.distance = distance;
          return distance <= radius;
        });

        const groupedFilteredCourses = filtered.reduce((acc, course) => {
          const { city } = course;
          if (!acc[city]) acc[city] = [];
          acc[city].push(course);
          return acc;
        }, {});

        setFilteredCourses(groupedFilteredCourses);
        setFiltering(false);
      }, 0);
    } else {
      setFilteredCourses(organizedCourses);
    }
  }, [userLocation, radius, courses, organizedCourses]);

  const handleLocationRequest = () => {
    if (navigator.geolocation) {
      setLoading(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ latitude, longitude });
          setShowModal(false);
          setLoading(false);
        },
        (error) => {
          console.error("Error fetching user's location:", error);
          handleLocationError(error);
        },
        {
          timeout: 10000, // 10 seconds timeout
          maximumAge: 0,
          enableHighAccuracy: false,
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
      setError('Geolocation not supported. Please select your course manually.');
      setShowModal(false);
      setLoading(false);
    }
  };

  const handleLocationError = (error) => {
    setLoading(false);
    setShowModal(false);

    switch (error.code) {
      case error.PERMISSION_DENIED:
        setError('Location access denied. Please allow location access or select your course manually.');
        break;
      case error.POSITION_UNAVAILABLE:
        setError('Location information is unavailable. Please try again or select your course manually.');
        break;
      case error.TIMEOUT:
        setError('Location request timed out. Please try again or select your course manually.');
        break;
      default:
        setError('An unknown error occurred. Please select your course manually.');
        break;
    }

    console.error('Geolocation error code:', error.code, '-', error.message);
  };

  const handleCancelLocation = () => {
    setUserLocation(null);
    setShowModal(false);
    setLoading(false);
  };

  // Fetch tournaments when a course is selected
  useEffect(() => {
    if (selectedCourse) {
      setLoading(true);
      const fetchTournaments = async () => {
        try {
          const token = localStorage.getItem('jwtToken');
          if (!token) {
            setError('Authentication failed. Please log in.');
            setLoading(false);
            return;
          }
          const response = await axios.get(
            `${connection}/tournaments/course/${selectedCourse}`,
            { headers: { Authorization: `Bearer ${token}` } }
          );
          setTournaments(response.data);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching tournaments:', error);
          // Do not set error; just set tournaments to an empty array
          setTournaments([]);
          setLoading(false);
        }
      };
      fetchTournaments();
    } else {
      setTournaments([]);
    }
  }, [selectedCourse]);

  if (loading || filtering) return <Spinner />;

  // Display error messages only for authentication or course loading errors
  if (error) {
    return (
      <section className="loading">
        <h3 className="loading__title">{error}</h3>
        {error.includes('Authentication') ? (
          <button>
            <Link to="/login">Please Log In</Link>
          </button>
        ) : (
          <button
            onClick={() => {
              setError(null);
              setUserLocation(null);
              setShowModal(false);
            }}
          >
            Select Course Manually
          </button>
        )}
      </section>
    );
  }

  return (
    <section className="select-course__container">
      <section className="select-course">
        <h2 className="select-course__title">Select Your Course and Tournament</h2>

        {/* Modal for asking user to allow location access */}
        {showModal && (
          <div className="modal__overlay">
            <div className="modal__front">
              <p className="modal__font">
                We need your location to show nearby courses. Please allow location access.
              </p>
              <button className="modal__button" onClick={handleLocationRequest}>
                Allow Location
              </button>
              <button className="modal__button" onClick={handleCancelLocation}>
                Cancel
              </button>
            </div>
          </div>
        )}

        <div className="select-course__content">
          {/* Course Selection */}
          <div className="select-course__section">
            <h3 className="select-course__heading">
              {userLocation ? `Courses within ${radius} km` : 'Select a Course'}
            </h3>

            {userLocation ? (
              Object.keys(filteredCourses).length > 0 ? (
                // Render nearby courses
                Object.entries(filteredCourses).map(([city, cityCourses]) => (
                  <div key={city} className="select-course__group">
                    <h4 className="select-course__city">{city}</h4>
                    {cityCourses.map(({ course_id, course_name, distance }) => (
                      <div key={course_id} className="select-course__item">
                        <input
                          type="radio"
                          id={`course-${course_id}`}
                          name="selectedCourse"
                          value={course_id}
                          onChange={(e) => {
                            setSelectedCourse(e.target.value);
                            setSelectedBlock('');
                            setSelectedTournament('');
                          }}
                        />
                        <label htmlFor={`course-${course_id}`}>
                          {course_name} {distance ? `- ${distance.toFixed(2)} km` : ''}
                        </label>
                      </div>
                    ))}
                  </div>
                ))
              ) : (
                // No nearby courses found
                <>
                  <p className="error-message">
                    No nearby courses found. Please select your course manually.
                  </p>
                  <button
                    className="manual-select-button"
                    onClick={() => {
                      setUserLocation(null);
                      setShowModal(false);
                    }}
                  >
                    Select Course Manually
                  </button>
                </>
              )
            ) : (
              // Non-location based selection: select city first
              <>
                <div className="select-course__section">
                  <label htmlFor="city-select">Select City:</label>
                  <select
                    id="city-select"
                    value={selectedCity}
                    onChange={(e) => {
                      setSelectedCity(e.target.value);
                      setSelectedCourse('');
                      setSelectedBlock('');
                      setSelectedTournament('');
                    }}
                  >
                    <option value="" hidden>
                      Select City
                    </option>
                    {Object.keys(organizedCourses).map((city) => (
                      <option key={city} value={city}>
                        {city}
                      </option>
                    ))}
                  </select>
                </div>

                {selectedCity && (
                  <div className="select-course__section">
                    <label htmlFor="course-select">Select Course:</label>
                    <select
                      id="course-select"
                      value={selectedCourse}
                      onChange={(e) => {
                        setSelectedCourse(e.target.value);
                        setSelectedBlock('');
                        setSelectedTournament('');
                      }}
                    >
                      <option value="" hidden>
                        Select Course
                      </option>
                      {organizedCourses[selectedCity].map((course) => (
                        <option key={course.course_id} value={course.course_id}>
                          {course.course_name}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
              </>
            )}
          </div>

          {/* Block Selection */}
          {selectedCourse && (
            <div className="select-course__section">
              <label htmlFor="select-course__blocks">Block:</label>
              <select
                id="select-course__blocks"
                value={selectedBlock}
                onChange={(e) => setSelectedBlock(e.target.value)}
              >
                <option value="" hidden>
                  Select Block
                </option>
                {courses
                  .find((course) => course.course_id === Number(selectedCourse))
                  ?.tee_off_block.split(',')
                  .map((block) => (
                    <option key={block.trim()} value={block.trim()}>
                      {block.trim()}
                    </option>
                  ))}
              </select>
            </div>
          )}

          {/* Tournament Selection */}
          {tournaments.length > 0 && (
            <div className="select-course__section">
              <label htmlFor="select-course__tournament">Tournament:</label>
              <select
                id="select-course__tournament"
                value={selectedTournament}
                onChange={(e) => setSelectedTournament(e.target.value)}
              >
                <option value="">No Tournament</option>
                {tournaments.map((tournament) => (
                  <option key={tournament.tournament_id} value={tournament.tournament_id}>
                    {tournament.tournament_name}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>

        {/* Start Button */}
        <Link
          to={`/track/${selectedCourse}/${encodeURIComponent(selectedBlock)}/${
            selectedTournament || ''
          }`}
        >
          <button
            className="select-course__start"
            disabled={!selectedCourse || !selectedBlock}
          >
            Tee Off
          </button>
        </Link>
      </section>
    </section>
  );
};

export default CourseSelect;
