import React, { useState, useEffect } from 'react';
import './GroupScoreInput.scss';
import nextHoleIcon from '../../assets/icons/arrow-forward.png';
import prevHoleIcon from '../../assets/icons/arrow-back.png';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

const connection = process.env.REACT_APP_API_URL;

const GroupScoreInput = () => {
  const navigate = useNavigate();
  const { id, block, groupId } = useParams(); // Ensure parameter names match the route

  // State variables
  const [userId, setUserId] = useState(null);
  const [courseData, setCourseData] = useState(null);
  const [holeData, setHoleData] = useState([]);
  const [totalHoles, setTotalHoles] = useState(0);
  const [currentHole, setCurrentHole] = useState(0);
  const [holes, setHoles] = useState([]);
  const [fairwaysHit, setFairwaysHit] = useState([]);
  const [greensInRegulation, setGreensInRegulation] = useState([]);
  const [twoPutts, setTwoPutts] = useState([]);
  const [putts, setPutts] = useState([]);
  const [totalScore, setTotalScore] = useState(0);
  const [overUnderPar, setOverUnderPar] = useState(0);
  const [advancedStats, setAdvancedStats] = useState({
    fairwaysHitPercentage: 0,
    greensInRegulationPercentage: 0,
    twoPuttsPercentage: 0,
  });
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [error, setError] = useState(null);

  // Fetch user ID
  useEffect(() => {
    const fetchUserId = async () => {
      try {
        const token = localStorage.getItem('jwtToken');
        if (!token) {
          setError('Authentication failed. Please log in.');
          return;
        }
        const response = await axios.get(`${connection}/user`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUserId(response.data.user_id);
      } catch (err) {
        console.error('Error fetching user ID:', err);
        setError('Failed to fetch user data. Please try again.');
      }
    };
    fetchUserId();
  }, []);

  // Fetch course and hole data
  useEffect(() => {
    const fetchCourseData = async () => {
      try {
        const token = localStorage.getItem('jwtToken');
        if (!token) {
          setError('Authentication failed. Please log in.');
          return;
        }
        const response = await axios.get(`${connection}/track/${id}/${block}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const { courseData, holesData } = response.data;
        if (courseData && holesData) {
          setCourseData(courseData);
          setHoleData(holesData);
          setTotalHoles(courseData.num_holes);
          setHoles(Array(courseData.num_holes).fill(0));
          setFairwaysHit(Array(courseData.num_holes).fill(false));
          setGreensInRegulation(Array(courseData.num_holes).fill(false));
          setTwoPutts(Array(courseData.num_holes).fill(false));
          setPutts(Array(courseData.num_holes).fill(0));
        } else {
          setError('Failed to load course data.');
        }
      } catch (err) {
        console.error('Error fetching course data:', err);
        setError('Failed to load course data. Please try again.');
      }
    };
    fetchCourseData();
  }, [id, block]);

  // Load saved scores from localStorage
  useEffect(() => {
    const savedScores = JSON.parse(localStorage.getItem('savedScores'));
    if (savedScores && savedScores.courseId === id) {
      setHoles(savedScores.holes);
      setCurrentHole(savedScores.currentHole);
      setFairwaysHit(savedScores.fairwaysHit);
      setGreensInRegulation(savedScores.greensInRegulation);
      setPutts(savedScores.putts);
      setTwoPutts(savedScores.twoPutts);
    }
  }, [id]);

  // Save scores to localStorage
  useEffect(() => {
    if (courseData) {
      localStorage.setItem(
        'savedScores',
        JSON.stringify({
          courseId: id,
          holes,
          currentHole,
          fairwaysHit,
          greensInRegulation,
          twoPutts,
          putts,
        })
      );
    }
  }, [id, holes, currentHole, fairwaysHit, greensInRegulation, twoPutts, putts, courseData]);

  // Calculate total score and advanced stats
  useEffect(() => {
    if (holes.length && holeData.length) {
      const scoreSum = holes.reduce((acc, curr) => acc + curr, 0);
      setTotalScore(scoreSum);

      const totalPar = holeData.reduce((acc, curr) => acc + curr.par, 0);
      setOverUnderPar(scoreSum - totalPar);

      const validHolesCount = holes.filter((score) => score > 0).length;
      const fairwaysHitCount = fairwaysHit.filter((hit) => hit).length;
      const greensInRegulationCount = greensInRegulation.filter((gir) => gir).length;
      const twoPuttsCount = twoPutts.filter((tp) => tp).length;

      setAdvancedStats({
        fairwaysHitPercentage: validHolesCount
          ? ((fairwaysHitCount / validHolesCount) * 100).toFixed(2)
          : 0,
        greensInRegulationPercentage: validHolesCount
          ? ((greensInRegulationCount / validHolesCount) * 100).toFixed(2)
          : 0,
        twoPuttsPercentage: validHolesCount
          ? ((twoPuttsCount / validHolesCount) * 100).toFixed(2)
          : 0,
      });
    }
  }, [holes, holeData, fairwaysHit, greensInRegulation, twoPutts]);

  // Handle score increment/decrement
  const incrementScore = () => {
    setHoles((prevHoles) => {
      const updatedHoles = [...prevHoles];
      updatedHoles[currentHole] = (updatedHoles[currentHole] || 0) + 1;
      return updatedHoles;
    });
  };

  const decrementScore = () => {
    setHoles((prevHoles) => {
      const updatedHoles = [...prevHoles];
      updatedHoles[currentHole] = Math.max((updatedHoles[currentHole] || 0) - 1, 0);
      return updatedHoles;
    });
  };

  // Handle input change
  const handleInputChange = (e) => {
    const value = parseInt(e.target.value, 10);
    setHoles((prevHoles) => {
      const updatedHoles = [...prevHoles];
      updatedHoles[currentHole] = isNaN(value) ? 0 : value;
      return updatedHoles;
    });
  };

  // Handle checkbox change
  const handleCheckboxChange = (statType) => {
    const updateStat = (prevState) => {
      const updatedStat = [...prevState];
      updatedStat[currentHole] = !updatedStat[currentHole];
      return updatedStat;
    };

    switch (statType) {
      case 'fairwaysHit':
        setFairwaysHit(updateStat);
        break;
      case 'greensInRegulation':
        setGreensInRegulation(updateStat);
        break;
      case 'twoPutts':
        setTwoPutts(updateStat);
        break;
      default:
        break;
    }
  };

  // Handle navigation between holes
  const handleNextHole = () => {
    if (currentHole < totalHoles - 1) {
      setCurrentHole(currentHole + 1);
    }
  };

  const handlePreviousHole = () => {
    if (currentHole > 0) {
      setCurrentHole(currentHole - 1);
    }
  };

  // Save score to server
  const saveScore = async () => {
    try {
      const gameDate = new Date().toISOString().split('T')[0]; // YYYY-MM-DD format
      const roundId = uuidv4();
      const token = localStorage.getItem('jwtToken');

      const holeScoreObjects = [];
      const groupScoreObjects = [];
      const roundSummary = {
        round_id: roundId,
        user_id: userId,
        party_id: groupId,
        course_id: courseData.course_id,
        round_date: gameDate,
        total_par: holeData.reduce((acc, curr) => acc + curr.par, 0),
        total_score: totalScore,
      };

      for (let i = 0; i < totalHoles; i++) {
        if (holes[i] > 0) {
          const scoreId = uuidv4();
          const holeScoreObj = {
            score_id: scoreId,
            round_id: roundId,
            user_id: userId,
            course_id: courseData.course_id,
            game_date: gameDate,
            block: block,
            hole_number: i + 1,
            hole_score: holes[i],
            fairway_hit: fairwaysHit[i],
            green_in_regulation: greensInRegulation[i],
            two_putts: twoPutts[i],
            putts: putts[i],
          };
          const groupScoreObj = {
            score_id: scoreId,
            round_id: roundId,
            user_id: userId,
            course_id: courseData.course_id,
            hole_number: i + 1,
            hole_par: holeData[i].par,
            group_id: groupId,
            user_score: holes[i],
            block: block,
          };

          holeScoreObjects.push(holeScoreObj);
          groupScoreObjects.push(groupScoreObj);
        }
      }

      // Save individual scores
      await axios.post(`${connection}/savescore`, holeScoreObjects, {
        headers: { Authorization: `Bearer ${token}` },
      });

      // Save group scores
      await axios.post(`${connection}/tours/savetourscore`, groupScoreObjects, {
        headers: { Authorization: `Bearer ${token}` },
      });

      // Save round summary
      await axios.post(`${connection}/tours/newround`, [roundSummary], {
        headers: { Authorization: `Bearer ${token}` },
      });

      // Clear saved scores and navigate to group scores page
      localStorage.removeItem('savedScores');
      navigate(`/tours/scores/${groupId}`);
    } catch (error) {
      console.error('Error saving score:', error);
      setError('Failed to save scores. Please try again.');
    }
  };

  // Handle score save confirmation
  const handleScoreSave = (e) => {
    e.preventDefault();
    setShowConfirmation(true);
  };

  const confirmSave = () => {
    setShowConfirmation(false);
    saveScore();
  };

  const closeModal = () => {
    setShowConfirmation(false);
  };

  // Render error message if any
  if (error) {
    return (
      <section className="error-message">
        <h3>{error}</h3>
        <button onClick={() => navigate('/login')}>Please Log In</button>
      </section>
    );
  }

  // Render loading state
  if (!courseData || !holeData.length) {
    return (
      <section className="loading">
        <h3>Loading course data...</h3>
      </section>
    );
  }

  return (
    <div className="input__container">
      <section className="header__image"></section>

      <section className="score">
        <div className="score__info">
          <p className="score__course">{courseData.course_name}</p>

          <div className="score__results">
            <h4 className="score__total">Total Score: {totalScore}</h4>
            <h4 className="score__result">
              Over/Under: {overUnderPar >= 0 ? `+${overUnderPar}` : overUnderPar}
            </h4>
          </div>

          <div className="score__data">
            <h2 className="score__hole">Hole {currentHole + 1}</h2>

            <div className="score__nums">
              <h4 className="score__par">
                {holeData[currentHole]?.[`${block}_yards`]} yards
              </h4>
              <h4 className="score__distance">Par: {holeData[currentHole]?.par}</h4>
            </div>
          </div>
        </div>

        <div className="score__tracker">
          <button className="score__decrement" onClick={decrementScore}>
            -
          </button>

          <input
            type="number"
            className="score__input"
            value={holes[currentHole] || ''}
            onChange={handleInputChange}
          />

          <button className="score__increment" onClick={incrementScore}>
            +
          </button>
        </div>

        <div className="score__stats">
          <label className="score__stats__stat-label">
            F.H.
            <input
              type="checkbox"
              checked={fairwaysHit[currentHole]}
              onChange={() => handleCheckboxChange('fairwaysHit')}
            />
          </label>
          <label className="score__stats__stat-label">
            G.I.R.
            <input
              type="checkbox"
              checked={greensInRegulation[currentHole]}
              onChange={() => handleCheckboxChange('greensInRegulation')}
            />
          </label>
          <label className="score__stats__stat-label">
            2-P
            <input
              type="checkbox"
              checked={twoPutts[currentHole]}
              onChange={() => handleCheckboxChange('twoPutts')}
            />
          </label>
        </div>

        <div className="score__control">
          <div className="score__view">
            <button className="score__previous" onClick={handlePreviousHole}>
              <img src={prevHoleIcon} alt="Previous Hole" />
            </button>

            <button className="score__next" onClick={handleNextHole}>
              <img src={nextHoleIcon} alt="Next Hole" />
            </button>
          </div>

          <button className="score__save" onClick={handleScoreSave}>
            End Round
          </button>
        </div>
      </section>

      {showConfirmation && (
        <div className="modal__overlay">
          <div className="modal__front">
            <p className="modal__font">Are you sure you want to end the round?</p>
            <button className="modal__button" onClick={confirmSave}>
              Yes
            </button>
            <button className="modal__button" onClick={closeModal}>
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default GroupScoreInput;
