import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './UserStats.scss';
import { FaGolfBall, FaFlag, FaChartLine } from 'react-icons/fa';
import { GiGolfFlag, GiGolfClub } from 'react-icons/gi';
import { GiGolfTee } from "react-icons/gi";
import { RiGolfBallFill } from "react-icons/ri";

const connection = process.env.REACT_APP_API_URL;

const UserStats = () => {
    const [user, setUser] = useState(null);
    const [stats, setStats] = useState({
        averageScore: null,
        averagePars: null,
        fairwaysHitPercentage: null,
        greensInRegulationPercentage: null,
        twoPuttsPercentage: null,
        handicapIndex: null,
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [failedAuth, setFailedAuth] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem("jwtToken");
                
                if (!token) {
                    setFailedAuth(true);
                    setLoading(false);
                    return;
                }
        
                const headers = {
                    Authorization: `Bearer ${token}`,
                };
        
                const responseUser = await axios.get(`${connection}/user`, { headers });
                const userData = responseUser.data;
                setUser(userData);
        
                if (userData && userData.user_id) {
                    const responseStats = await axios.get(`${connection}/user/${userData.user_id}/stats`, { headers });
        
                    let handicapIndex = null;
                    try {
                        // Attempt to fetch handicap data
                        const responseHandicap = await axios.get(`${connection}/user/${userData.user_id}/handicap`, { headers });
                        handicapIndex = responseHandicap.data.handicapIndex;
                    } catch (error) {
                        if (error.response && error.response.status === 404) {
                            // Handle case where handicap is not found
                            console.warn('Handicap not found, setting to null.');
                            handicapIndex = "Not available yet";  // Or any message you want to show
                        } else {
                            throw error;  // For other errors, rethrow
                        }
                    }
        
                    const statsData = responseStats.data;
                    setStats({
                        averageScore: statsData.averageScore,
                        averagePars: statsData.averagePars,
                        fairwaysHitPercentage: statsData.fairwaysHitPercentage,
                        greensInRegulationPercentage: statsData.greensInRegulationPercentage,
                        twoPuttsPercentage: statsData.twoPuttsPercentage,
                        handicapIndex: handicapIndex,  // Set handicap index or default message
                    });
                } else {
                    throw new Error("User ID is undefined");
                }
        
                setLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error.response || error.message);
                setError(error.message);
                setLoading(false);
            }
        };              

        fetchData();  // Call fetchData inside useEffect

    }, []); // Empty dependency array means this will only run once on mount

    if (loading) {
        return <div className="user-stats loading">Loading...</div>;
    }

    if (error) {
        return <div className="user-stats error">Error: {error}</div>;
    }

    if (failedAuth) {
        return <div className="user-stats error">Authentication failed. Please log in again.</div>;
    }

    return (
        <div className="user__statistics">
            <h2 className="user__statistics--title">Your Stats</h2>
            <div className="user__statistics--container">
                <div className="stat__card">
    <GiGolfTee className="stat__icon" />
    <div className="stat__values--container">
        <span className="stat__label">Handicap</span>
        <span className="stat__value">
            {stats.handicapIndex ? stats.handicapIndex : "Not available yet"}
        </span>
    </div>
</div>
                <div className="stat__card">
                    <FaChartLine className="stat__icon" />
                    <div className="stat__values--container">
                        <span className="stat__label">Score/18</span>
                        <span className="stat__value">{stats.averageScore}</span>
                    </div>
                </div>
                <div className="stat__card">
                    <FaFlag className="stat__icon" />
                    <div className="stat__values--container">
                        <span className="stat__label">Pars/18</span>
                        <span className="stat__value">{stats.averagePars}</span>
                    </div>
                </div>
                <div className="stat__card">
                    <GiGolfTee className="stat__icon" />
                    <div className="stat__values--container">
                        <span className="stat__label">Fairways Hit</span>
                        <span className="stat__value">{stats.fairwaysHitPercentage}%</span>
                    </div>
                </div>
                <div className="stat__card">
                    <RiGolfBallFill className="stat__icon" />
                    <div className="stat__values--container">
                        <span className="stat__label">G.I.R.</span>
                        <span className="stat__value">{stats.greensInRegulationPercentage}%</span>
                    </div>
                </div>
                <div className="stat__card">
                    <GiGolfFlag className="stat__icon" />
                    <div className="stat__values--container">
                        <span className="stat__label">2-Putts</span>
                        <span className="stat__value">{stats.twoPuttsPercentage}%</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserStats;
